<template>
    <div>
        <div class="alert alert-success">
            Merci ! Votre paiement a bien été accepté. Vos crédits vous seront attribués dans un instant.
        </div>
    </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: 'PaiementStripeConfirmation',
    data() {
        return {}
    },
    methods: {
        ...mapActions('auth', ['me']),
        checkCredits() {
            console.log('ok')
            this.me(true)
        }
    },
    mounted() {
        setTimeout(this.checkCredits, 10000)
    }
}
</script>
